import { createRouter, createWebHistory, RouteRecordRaw,useRouter } from 'vue-router'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/fill',
    name: 'fill',
    component: () => import('../views/FillView.vue')
  },
  {
    path: '/wait',
    name: 'wait',
    component: () => import('../views/WaitView.vue')
  },
  {
    path: '/show',
    name: 'show',
    component: () => import('../views/ShowView.vue')
  },
  {
    path: '/provide',
    name: 'provide',
    component: () => import('../views/ProvideView.vue')
  },
  {
    path:'/nummanbind',
    name:'nummanbind',
    component:() => import('../views/NummanView.vue')
  },
  {
    path:'/refinement',
    name:'refinement',
    component:() => import('../views/RefineMent.vue')
  },
  {
    path: '/action',
    name: 'action',
    component: () => import('../views/ActionView.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/NewsView.vue')
  },
  {
    path: '/aboutus',
    name: 'aboutus',
    component: () => import('../views/AboutUs.vue')
  },
  {
    path: '/deatil',
    name: 'actiondeatil',
    component: () => import('../views/ActionDeatils.vue')
  },
  {
    path: '/newsdeatil',
    name: 'newsdeatil',
    component: () => import('../views/NewsDeatil.vue')
  },
  {
    path:'/titlecontent',
    name:'titlecontent',
    component:() => import('../views/TitleView.vue')
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('../views/ErrorView.vue')
  },
  {
    path: '/szzc',
    name: 'szzc',
    component: () => import('../views/SzzcView.vue')
  },
  {
    path: '/szzcdeatil',
    name: 'szzcdeatil',
    component: () => import('../views/SzzcDeatil.vue')
  },
  {
    path: '/solutiondeatil',
    name: 'solutiondeatil',
    component: () => import('../views/SolutionDeatil.vue')
  },
  {
    path: '/homeswiperdeatil',
    name: 'homeswiperdeatil',
    component: () => import('../views/HomeswiperDeatil.vue')
  },
  {
    path: '/download',
    name: 'download',
    component: () => import('../views/DownloadView.vue')
  },
  {
    path: '/qrcode',
    name: 'qrcode',
    component: () => import('../views/QrCode.vue')
  },
  {
    path: '/503',
    name: '503',
    component: () => import('../views/LoadView.vue')
  },
  {
    path: '/',
    redirect:'/home'
  } 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// 路由守卫：例如在“About”页面点击时未完成，就跳转到503页面
router.beforeEach((to, from, next) => {
  const notReadyPages = ['show']; // 未完成的页面
  if (typeof to.name === 'string' && notReadyPages.includes(to.name)) {
    next({ name: '503' });
  } else {
    next();
  }
});

export default router
